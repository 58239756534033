<template>
  <v-container id="loginPhone" class="d-flex flex-column justify-space-between h-100" fluid>
    <div class="inner-block">
      <h1 class="subtitle is-2 text-center mt-3 mb-3">Телефон</h1>
      <div class="columns">
        <div class="column text-center">
          <h3 class="subtitle-1 mb-1">Вы готовы подтвердить свой телефон?</h3>
          <h3 class="subtitle-2 mb-3">Это нужно, чтобы с вами могли связаться клиенты или перевозчики</h3>
        </div>
      </div>
    </div>
    <div class="text-center mb-3">
      <b-button
        class="mt-0 w-100"
        type="is-primary"
        @click="goToConfirmPhone"
      >
        Отправить код
      </b-button>
      <b-button
        class="mt-2 w-100"
        type="is-text"
        @click="notNow"
      >
        Не сейчас
      </b-button>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'verificationCode',
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    goToConfirmPhone() {
      this.$router.push('/verification-code');
    },
    notNow() {
      this.$router.push('/activity-feed');
    },
  },
};
</script>

<style>
#loginPhone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 24px); /* Займем всю высоту экрана */
}

#loginPhone .inner-block {
  margin-top: 30%;
}

#loginPhone .v-input .v-input__icon .v-icon.notranslate.material-icons {
  font-style: normal !important;
  margin-right: 10px !important;
}

#loginPhone .subtitle-1 {
  color: #4a4a4a;
  line-height: 1.3rem;
}

#loginPhone .subtitle-2 {
  color: #4a4a4a;
   font-weight: 400;
}

#loginPhone .help {
  font-size: 0.95rem;
  color: #4a4a4a;
}

#loginPhone .help.text-danger,
#loginPhone .help.text-danger .v-icon {
  color: #ff5252;
}
</style>
